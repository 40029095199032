let cmsUrl = 'http://localhost:8001'

if (process.env.GATSBY_ENV === 'dev') {
  cmsUrl = 'https://ohscms.actu.net.au'
} else if (process.env.GATSBY_ENV === 'prod') {
  cmsUrl = 'https://ohscms.australianunions.org.au'
}

const constants = {
  cmsUrl: cmsUrl,
  supportEmail: 'ohshelp@actu.org.au'
}

export default constants
