import constants from '../constants'
import axios from 'axios'
import { Value } from 'baseui/select'

export async function getData(url = '', headers = {}) {
  const response = await axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      throw error
    })
  return response
}

export async function postFormData(file: File | null, url = '', headers = {}, data = {}) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  if (file) {
    formData.append('photo', file)
  }

  const response = await axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      throw error
    })
  return response
}

async function putFormData(file: File | null, url = '', headers = {}, data = {}) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  if (file) {
    formData.append('photo', file)
  }

  const response = await axios
    .put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      throw error
    })
  return response
}

export interface Issue {
  name: string
  id?: number
  category_id: number | null
  category?: string
  location?: string
  start_date: string
  end_date?: string | null
  start_time?: string | null
  ongoing?: boolean
  photo?: string
  notes?: string
  email?: string
  reminder?: string
  resolved?: boolean
  recipients?: string[]
}

export function createIssue(data: Issue, file: File, token: string | null) {
  const url = `${constants.cmsUrl}/issues/`
  return postFormData(file, url, { Authorization: 'Token ' + token }, data)
}

export function updateIssue(
  id: string | number,
  data: Issue,
  file: File | null,
  token: string | null
) {
  const url = `${constants.cmsUrl}/issues/${id}/`
  return putFormData(file, url, { Authorization: 'Token ' + token }, data)
}

export interface ListIssuesResponse {
  issues: Issue[]
}

export function listIssues(token: string | null): Promise<ListIssuesResponse> {
  const url = `${constants.cmsUrl}/issues/`
  return getData(url, { Authorization: 'Token ' + token })
}
export function getIssue(token: string | null, issueId: string): Promise<Issue> {
  const url = `${constants.cmsUrl}/issues/${issueId}?with_recipients=true`
  return getData(url, { Authorization: 'Token ' + token })
}

export interface LiteHazardPagesResponseItem {
  id: number
  meta: {
    detail_url: string
    first_published_at: string
    html_url: string
    slug: string
    type: 'home.HazardPage'
  }
  title: string
}

interface LiteNewsPagesResponseItem {
  id: number
  meta: {
    detail_url: string
    first_published_at: string
    html_url: string
    slug: string
    type: 'home.NewsPage'
  }
  title: string
}

export interface TableItem {
  type: 'table'
  value: {
    data: string[][]
  }
  id: string
}

export interface HazardPagesResponseItem extends LiteHazardPagesResponseItem {
  hsr_video: string
  hsr_main_text: string
  hsr_table: TableItem[]
  hsr_know_box: string
  hsr_assess_the_risks: string
  hsr_control_the_risks: string
  hsr_next_steps: string
  hsr_find_out_more: string
  org_video: string
  org_main_text: string
  org_table: TableItem[]
  org_know_box: string
  org_assess_the_risks: string
  org_control_the_risks: string
  org_next_steps: string
  org_find_out_more: string
}

export interface NewsPagesResponseItem extends LiteNewsPagesResponseItem {
  title_visible: boolean
  video: string
  main_text: string
  button_linked_page?: {
    id: number
    meta: {
      type: string
      detail_url: string
    }
    title: string
  }
  link_button_text?: string
}

export interface MostRecentNewsResponseItem extends LiteNewsPagesResponseItem {
  subtitle?: string
  news_image?: {
    meta: {
      download_url: string
    }
  }
  hazard_category?: {
    id: number
    meta: {
      type: 'home.HazardPage'
      detail_url: string
    }
    title: string
  }
}

export interface HazardPagesResponse {
  items: HazardPagesResponseItem[]
  meta: {
    total_count: number
  }
}

export interface NewsPagesResponse {
  items: NewsPagesResponseItem[]
  meta: {
    total_count: number
  }
}

export interface ConditionPageItem {
  title: string
  text: string
}

export interface ConditionPageResponse {
  items: ConditionPageItem[]
  meta: {
    total_count: number
  }
}

export interface MostRecentNewsResponse {
  items: MostRecentNewsResponseItem[]
  meta: {
    total_count: number
  }
}

export interface HazardPagesListResponse {
  items: LiteHazardPagesResponseItem[]
  meta: {
    total_count: number
  }
}

export function getHazardList(token: string): Promise<void | HazardPagesListResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.HazardPage&order=title&limit=200`
  return getData(url, { Authorization: 'Token ' + token })
}

export function searchHazards(
  token: string,
  query: string,
  role: string
): Promise<void | HazardPagesListResponse> {
  const searchFields = [
    'main_text',
    'know_box',
    'assess_the_risks',
    'control_the_risks',
    'next_steps',
    'find_out_more'
  ]
  const targetFields = searchFields
    .map(field => role.slice(0, 3).toLowerCase() + '_' + field)
    .join(',')
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.HazardPage&search=${query}&targets=${targetFields},title`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getHazardData(
  token: string,
  hazardId: string
): Promise<void | HazardPagesResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.HazardPage&id=${hazardId}&fields=*`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getNewsData(token: string, newsId: string): Promise<void | NewsPagesResponse> {
  const fields = [
    'title_visible',
    'video',
    'main_text',
    'button_linked_page',
    'link_button_text'
  ].join(',')
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.NewsPage&id=${newsId}&fields=${fields}`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getConditionsData(conditionsTitle: string): Promise<void | ConditionPageResponse> {
  const fields = ['title', 'text'].join(',')
  const url = `${constants.cmsUrl}/api/v2/public/?type=home.ConditionsPage&title=${conditionsTitle}&fields=${fields}`
  return getData(url)
}

export function getCurrentNews(token: string): Promise<void | MostRecentNewsResponse> {
  const fields = ['title', 'news_image', 'subtitle', 'hazard_category'].join(',')
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.NewsPage&fields=${fields}&order=-id`
  return getData(url, { Authorization: 'Token ' + token })
}

export interface DocumentsResponseItem {
  id: number
  meta: {
    detail_url: string
    download_url: string
    tags: string[]
    type: string
  }
  title: string
}

export interface DocumentsResponse {
  items: DocumentsResponseItem[]
  meta: {
    total_count: number
  }
}

export function getDocument(token: string, id: string): Promise<void | DocumentsResponse> {
  const url = `${constants.cmsUrl}/api/v2/documents/?id=${id}`
  return getData(url, { Authorization: 'Token ' + token })
}

export interface ImagesResponseItem {
  id: number
  meta: {
    detail_url: string
    download_url: string
    tags: string[]
    type: string
  }
  title: string
}

export interface ImagesResponse {
  items: ImagesResponseItem[]
  meta: {
    total_count: number
  }
}

export function getImage(token: string, id: string): Promise<void | ImagesResponse> {
  const url = `${constants.cmsUrl}/api/v2/images/?id=${id}`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getUnionLogo(token: string, union: string): Promise<void | ImagesResponse> {
  const url = `${constants.cmsUrl}/api/v2/images/?union=${union}`
  return getData(url, { Authorization: 'Token ' + token })
}

export interface LiteToolsPagesResponseItem {
  id: number
  meta: {
    detail_url: string
    first_published_at: string
    html_url: string
    slug: string
    type: 'home.ToolsPage'
  }
  title: string
}

interface PanelItem {
  type: 'panel'
  value: {
    heading: string
    content: string
  }
  id: string
}

interface AccordionItem {
  type: 'accordion'
  value: {
    heading: string
    panels: PanelItem[]
  }
  id: string
}

interface TextContentItem {
  type: 'text_block' | 'know_block'
  value: string
  id: string
}

type GenericContentItem = TextContentItem | AccordionItem
export type GenericContentStream = GenericContentItem[]

interface ToolsPagesResponseItem extends LiteToolsPagesResponseItem {
  guide: boolean
  special_purpose: string
  org_content: GenericContentStream
  hsr_content: GenericContentStream
}

export interface ToolsPagesResponse {
  items: ToolsPagesResponseItem[]
  meta: {
    total_count: number
  }
}
export interface ToolsPagesListResponse {
  items: LiteToolsPagesResponseItem[]
  meta: {
    total_count: number
  }
}
export function getToolsData(token: string, toolsId: string): Promise<void | ToolsPagesResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.ToolsPage&id=${toolsId}&fields=*`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getSpecialToolsPage(
  token: string,
  purpose: string
): Promise<void | ToolsPagesListResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.ToolsPage&special_purpose=${purpose}`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getGuideList(token: string): Promise<void | ToolsPagesListResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.ToolsPage&guide=true&order=title&limit=200`
  return getData(url, { Authorization: 'Token ' + token })
}

export function searchGuides(token: string, query: string): Promise<void | ToolsPagesListResponse> {
  const url = `${constants.cmsUrl}/api/v2/pages/?type=home.ToolsPage&guide=true&search=${query}`
  return getData(url, { Authorization: 'Token ' + token })
}

export function getUnions(): Promise<Value[]> {
  const url = `${constants.cmsUrl}/unions`
  return getData(url)
}
